:host, :root {
  --spectrum-global-animation-linear: cubic-bezier(0, 0, 1, 1);
  --spectrum-global-animation-duration-0: 0s;
  --spectrum-global-animation-duration-100: .13s;
  --spectrum-global-animation-duration-200: .16s;
  --spectrum-global-animation-duration-300: .19s;
  --spectrum-global-animation-duration-400: .22s;
  --spectrum-global-animation-duration-500: .25s;
  --spectrum-global-animation-duration-600: .3s;
  --spectrum-global-animation-duration-700: .35s;
  --spectrum-global-animation-duration-800: .4s;
  --spectrum-global-animation-duration-900: .45s;
  --spectrum-global-animation-duration-1000: .5s;
  --spectrum-global-animation-duration-2000: 1s;
  --spectrum-global-animation-duration-4000: 2s;
  --spectrum-global-animation-ease-in-out: cubic-bezier(.45, 0, .4, 1);
  --spectrum-global-animation-ease-in: cubic-bezier(.5, 0, 1, 1);
  --spectrum-global-animation-ease-out: cubic-bezier(0, 0, .4, 1);
  --spectrum-global-animation-ease-linear: cubic-bezier(0, 0, 1, 1);
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.10;
  --spectrum-global-color-static-black-rgb: 0, 0, 0;
  --spectrum-global-color-static-black: rgb(var(--spectrum-global-color-static-black-rgb));
  --spectrum-global-color-static-white-rgb: 255, 255, 255;
  --spectrum-global-color-static-white: rgb(var(--spectrum-global-color-static-white-rgb));
  --spectrum-global-color-static-blue-rgb: 0, 87, 191;
  --spectrum-global-color-static-blue: rgb(var(--spectrum-global-color-static-blue-rgb));
  --spectrum-global-color-static-gray-50-rgb: 255, 255, 255;
  --spectrum-global-color-static-gray-50: rgb(var(--spectrum-global-color-static-gray-50-rgb));
  --spectrum-global-color-static-gray-75-rgb: 255, 255, 255;
  --spectrum-global-color-static-gray-75: rgb(var(--spectrum-global-color-static-gray-75-rgb));
  --spectrum-global-color-static-gray-100-rgb: 255, 255, 255;
  --spectrum-global-color-static-gray-100: rgb(var(--spectrum-global-color-static-gray-100-rgb));
  --spectrum-global-color-static-gray-200-rgb: 235, 235, 235;
  --spectrum-global-color-static-gray-200: rgb(var(--spectrum-global-color-static-gray-200-rgb));
  --spectrum-global-color-static-gray-300-rgb: 217, 217, 217;
  --spectrum-global-color-static-gray-300: rgb(var(--spectrum-global-color-static-gray-300-rgb));
  --spectrum-global-color-static-gray-400-rgb: 179, 179, 179;
  --spectrum-global-color-static-gray-400: rgb(var(--spectrum-global-color-static-gray-400-rgb));
  --spectrum-global-color-static-gray-500-rgb: 146, 146, 146;
  --spectrum-global-color-static-gray-500: rgb(var(--spectrum-global-color-static-gray-500-rgb));
  --spectrum-global-color-static-gray-600-rgb: 110, 110, 110;
  --spectrum-global-color-static-gray-600: rgb(var(--spectrum-global-color-static-gray-600-rgb));
  --spectrum-global-color-static-gray-700-rgb: 71, 71, 71;
  --spectrum-global-color-static-gray-700: rgb(var(--spectrum-global-color-static-gray-700-rgb));
  --spectrum-global-color-static-gray-800-rgb: 34, 34, 34;
  --spectrum-global-color-static-gray-800: rgb(var(--spectrum-global-color-static-gray-800-rgb));
  --spectrum-global-color-static-gray-900-rgb: 0, 0, 0;
  --spectrum-global-color-static-gray-900: rgb(var(--spectrum-global-color-static-gray-900-rgb));
  --spectrum-global-color-static-red-400-rgb: 237, 64, 48;
  --spectrum-global-color-static-red-400: rgb(var(--spectrum-global-color-static-red-400-rgb));
  --spectrum-global-color-static-red-500-rgb: 217, 28, 21;
  --spectrum-global-color-static-red-500: rgb(var(--spectrum-global-color-static-red-500-rgb));
  --spectrum-global-color-static-red-600-rgb: 187, 2, 2;
  --spectrum-global-color-static-red-600: rgb(var(--spectrum-global-color-static-red-600-rgb));
  --spectrum-global-color-static-red-700-rgb: 154, 0, 0;
  --spectrum-global-color-static-red-700: rgb(var(--spectrum-global-color-static-red-700-rgb));
  --spectrum-global-color-static-red-800-rgb: 124, 0, 0;
  --spectrum-global-color-static-red-800: rgb(var(--spectrum-global-color-static-red-800-rgb));
  --spectrum-global-color-static-orange-400-rgb: 250, 139, 26;
  --spectrum-global-color-static-orange-400: rgb(var(--spectrum-global-color-static-orange-400-rgb));
  --spectrum-global-color-static-orange-500-rgb: 233, 117, 0;
  --spectrum-global-color-static-orange-500: rgb(var(--spectrum-global-color-static-orange-500-rgb));
  --spectrum-global-color-static-orange-600-rgb: 209, 97, 0;
  --spectrum-global-color-static-orange-600: rgb(var(--spectrum-global-color-static-orange-600-rgb));
  --spectrum-global-color-static-orange-700-rgb: 182, 80, 0;
  --spectrum-global-color-static-orange-700: rgb(var(--spectrum-global-color-static-orange-700-rgb));
  --spectrum-global-color-static-orange-800-rgb: 155, 64, 0;
  --spectrum-global-color-static-orange-800: rgb(var(--spectrum-global-color-static-orange-800-rgb));
  --spectrum-global-color-static-yellow-200-rgb: 250, 237, 123;
  --spectrum-global-color-static-yellow-200: rgb(var(--spectrum-global-color-static-yellow-200-rgb));
  --spectrum-global-color-static-yellow-300-rgb: 250, 224, 23;
  --spectrum-global-color-static-yellow-300: rgb(var(--spectrum-global-color-static-yellow-300-rgb));
  --spectrum-global-color-static-yellow-400-rgb: 238, 205, 0;
  --spectrum-global-color-static-yellow-400: rgb(var(--spectrum-global-color-static-yellow-400-rgb));
  --spectrum-global-color-static-yellow-500-rgb: 221, 185, 0;
  --spectrum-global-color-static-yellow-500: rgb(var(--spectrum-global-color-static-yellow-500-rgb));
  --spectrum-global-color-static-yellow-600-rgb: 201, 164, 0;
  --spectrum-global-color-static-yellow-600: rgb(var(--spectrum-global-color-static-yellow-600-rgb));
  --spectrum-global-color-static-yellow-700-rgb: 181, 144, 0;
  --spectrum-global-color-static-yellow-700: rgb(var(--spectrum-global-color-static-yellow-700-rgb));
  --spectrum-global-color-static-yellow-800-rgb: 160, 125, 0;
  --spectrum-global-color-static-yellow-800: rgb(var(--spectrum-global-color-static-yellow-800-rgb));
  --spectrum-global-color-static-chartreuse-300-rgb: 176, 222, 27;
  --spectrum-global-color-static-chartreuse-300: rgb(var(--spectrum-global-color-static-chartreuse-300-rgb));
  --spectrum-global-color-static-chartreuse-400-rgb: 157, 203, 13;
  --spectrum-global-color-static-chartreuse-400: rgb(var(--spectrum-global-color-static-chartreuse-400-rgb));
  --spectrum-global-color-static-chartreuse-500-rgb: 139, 182, 4;
  --spectrum-global-color-static-chartreuse-500: rgb(var(--spectrum-global-color-static-chartreuse-500-rgb));
  --spectrum-global-color-static-chartreuse-600-rgb: 122, 162, 0;
  --spectrum-global-color-static-chartreuse-600: rgb(var(--spectrum-global-color-static-chartreuse-600-rgb));
  --spectrum-global-color-static-chartreuse-700-rgb: 106, 141, 0;
  --spectrum-global-color-static-chartreuse-700: rgb(var(--spectrum-global-color-static-chartreuse-700-rgb));
  --spectrum-global-color-static-chartreuse-800-rgb: 90, 120, 0;
  --spectrum-global-color-static-chartreuse-800: rgb(var(--spectrum-global-color-static-chartreuse-800-rgb));
  --spectrum-global-color-static-celery-200-rgb: 126, 229, 114;
  --spectrum-global-color-static-celery-200: rgb(var(--spectrum-global-color-static-celery-200-rgb));
  --spectrum-global-color-static-celery-300-rgb: 87, 212, 86;
  --spectrum-global-color-static-celery-300: rgb(var(--spectrum-global-color-static-celery-300-rgb));
  --spectrum-global-color-static-celery-400-rgb: 48, 193, 61;
  --spectrum-global-color-static-celery-400: rgb(var(--spectrum-global-color-static-celery-400-rgb));
  --spectrum-global-color-static-celery-500-rgb: 15, 172, 38;
  --spectrum-global-color-static-celery-500: rgb(var(--spectrum-global-color-static-celery-500-rgb));
  --spectrum-global-color-static-celery-600-rgb: 0, 150, 20;
  --spectrum-global-color-static-celery-600: rgb(var(--spectrum-global-color-static-celery-600-rgb));
  --spectrum-global-color-static-celery-700-rgb: 0, 128, 15;
  --spectrum-global-color-static-celery-700: rgb(var(--spectrum-global-color-static-celery-700-rgb));
  --spectrum-global-color-static-celery-800-rgb: 0, 107, 15;
  --spectrum-global-color-static-celery-800: rgb(var(--spectrum-global-color-static-celery-800-rgb));
  --spectrum-global-color-static-green-400-rgb: 29, 169, 115;
  --spectrum-global-color-static-green-400: rgb(var(--spectrum-global-color-static-green-400-rgb));
  --spectrum-global-color-static-green-500-rgb: 0, 148, 97;
  --spectrum-global-color-static-green-500: rgb(var(--spectrum-global-color-static-green-500-rgb));
  --spectrum-global-color-static-green-600-rgb: 0, 126, 80;
  --spectrum-global-color-static-green-600: rgb(var(--spectrum-global-color-static-green-600-rgb));
  --spectrum-global-color-static-green-700-rgb: 0, 105, 65;
  --spectrum-global-color-static-green-700: rgb(var(--spectrum-global-color-static-green-700-rgb));
  --spectrum-global-color-static-green-800-rgb: 0, 86, 53;
  --spectrum-global-color-static-green-800: rgb(var(--spectrum-global-color-static-green-800-rgb));
  --spectrum-global-color-static-seafoam-200-rgb: 75, 206, 199;
  --spectrum-global-color-static-seafoam-200: rgb(var(--spectrum-global-color-static-seafoam-200-rgb));
  --spectrum-global-color-static-seafoam-300-rgb: 32, 187, 180;
  --spectrum-global-color-static-seafoam-300: rgb(var(--spectrum-global-color-static-seafoam-300-rgb));
  --spectrum-global-color-static-seafoam-400-rgb: 0, 166, 160;
  --spectrum-global-color-static-seafoam-400: rgb(var(--spectrum-global-color-static-seafoam-400-rgb));
  --spectrum-global-color-static-seafoam-500-rgb: 0, 145, 139;
  --spectrum-global-color-static-seafoam-500: rgb(var(--spectrum-global-color-static-seafoam-500-rgb));
  --spectrum-global-color-static-seafoam-600-rgb: 0, 124, 118;
  --spectrum-global-color-static-seafoam-600: rgb(var(--spectrum-global-color-static-seafoam-600-rgb));
  --spectrum-global-color-static-seafoam-700-rgb: 0, 103, 99;
  --spectrum-global-color-static-seafoam-700: rgb(var(--spectrum-global-color-static-seafoam-700-rgb));
  --spectrum-global-color-static-seafoam-800-rgb: 10, 83, 80;
  --spectrum-global-color-static-seafoam-800: rgb(var(--spectrum-global-color-static-seafoam-800-rgb));
  --spectrum-global-color-static-blue-200-rgb: 130, 193, 251;
  --spectrum-global-color-static-blue-200: rgb(var(--spectrum-global-color-static-blue-200-rgb));
  --spectrum-global-color-static-blue-300-rgb: 98, 173, 247;
  --spectrum-global-color-static-blue-300: rgb(var(--spectrum-global-color-static-blue-300-rgb));
  --spectrum-global-color-static-blue-400-rgb: 66, 151, 244;
  --spectrum-global-color-static-blue-400: rgb(var(--spectrum-global-color-static-blue-400-rgb));
  --spectrum-global-color-static-blue-500-rgb: 27, 127, 245;
  --spectrum-global-color-static-blue-500: rgb(var(--spectrum-global-color-static-blue-500-rgb));
  --spectrum-global-color-static-blue-600-rgb: 4, 105, 227;
  --spectrum-global-color-static-blue-600: rgb(var(--spectrum-global-color-static-blue-600-rgb));
  --spectrum-global-color-static-blue-700-rgb: 0, 87, 190;
  --spectrum-global-color-static-blue-700: rgb(var(--spectrum-global-color-static-blue-700-rgb));
  --spectrum-global-color-static-blue-800-rgb: 0, 72, 153;
  --spectrum-global-color-static-blue-800: rgb(var(--spectrum-global-color-static-blue-800-rgb));
  --spectrum-global-color-static-indigo-200-rgb: 178, 181, 255;
  --spectrum-global-color-static-indigo-200: rgb(var(--spectrum-global-color-static-indigo-200-rgb));
  --spectrum-global-color-static-indigo-300-rgb: 155, 159, 255;
  --spectrum-global-color-static-indigo-300: rgb(var(--spectrum-global-color-static-indigo-300-rgb));
  --spectrum-global-color-static-indigo-400-rgb: 132, 137, 253;
  --spectrum-global-color-static-indigo-400: rgb(var(--spectrum-global-color-static-indigo-400-rgb));
  --spectrum-global-color-static-indigo-500-rgb: 109, 115, 246;
  --spectrum-global-color-static-indigo-500: rgb(var(--spectrum-global-color-static-indigo-500-rgb));
  --spectrum-global-color-static-indigo-600-rgb: 87, 93, 232;
  --spectrum-global-color-static-indigo-600: rgb(var(--spectrum-global-color-static-indigo-600-rgb));
  --spectrum-global-color-static-indigo-700-rgb: 68, 74, 208;
  --spectrum-global-color-static-indigo-700: rgb(var(--spectrum-global-color-static-indigo-700-rgb));
  --spectrum-global-color-static-indigo-800-rgb: 68, 74, 208;
  --spectrum-global-color-static-indigo-800: rgb(var(--spectrum-global-color-static-indigo-800-rgb));
  --spectrum-global-color-static-purple-400-rgb: 178, 121, 250;
  --spectrum-global-color-static-purple-400: rgb(var(--spectrum-global-color-static-purple-400-rgb));
  --spectrum-global-color-static-purple-500-rgb: 161, 93, 246;
  --spectrum-global-color-static-purple-500: rgb(var(--spectrum-global-color-static-purple-500-rgb));
  --spectrum-global-color-static-purple-600-rgb: 142, 67, 234;
  --spectrum-global-color-static-purple-600: rgb(var(--spectrum-global-color-static-purple-600-rgb));
  --spectrum-global-color-static-purple-700-rgb: 120, 43, 216;
  --spectrum-global-color-static-purple-700: rgb(var(--spectrum-global-color-static-purple-700-rgb));
  --spectrum-global-color-static-purple-800-rgb: 98, 23, 190;
  --spectrum-global-color-static-purple-800: rgb(var(--spectrum-global-color-static-purple-800-rgb));
  --spectrum-global-color-static-fuchsia-400-rgb: 228, 93, 230;
  --spectrum-global-color-static-fuchsia-400: rgb(var(--spectrum-global-color-static-fuchsia-400-rgb));
  --spectrum-global-color-static-fuchsia-500-rgb: 211, 63, 212;
  --spectrum-global-color-static-fuchsia-500: rgb(var(--spectrum-global-color-static-fuchsia-500-rgb));
  --spectrum-global-color-static-fuchsia-600-rgb: 188, 39, 187;
  --spectrum-global-color-static-fuchsia-600: rgb(var(--spectrum-global-color-static-fuchsia-600-rgb));
  --spectrum-global-color-static-fuchsia-700-rgb: 163, 10, 163;
  --spectrum-global-color-static-fuchsia-700: rgb(var(--spectrum-global-color-static-fuchsia-700-rgb));
  --spectrum-global-color-static-fuchsia-800-rgb: 135, 0, 136;
  --spectrum-global-color-static-fuchsia-800: rgb(var(--spectrum-global-color-static-fuchsia-800-rgb));
  --spectrum-global-color-static-magenta-200-rgb: 253, 127, 175;
  --spectrum-global-color-static-magenta-200: rgb(var(--spectrum-global-color-static-magenta-200-rgb));
  --spectrum-global-color-static-magenta-300-rgb: 242, 98, 157;
  --spectrum-global-color-static-magenta-300: rgb(var(--spectrum-global-color-static-magenta-300-rgb));
  --spectrum-global-color-static-magenta-400-rgb: 226, 68, 135;
  --spectrum-global-color-static-magenta-400: rgb(var(--spectrum-global-color-static-magenta-400-rgb));
  --spectrum-global-color-static-magenta-500-rgb: 205, 40, 111;
  --spectrum-global-color-static-magenta-500: rgb(var(--spectrum-global-color-static-magenta-500-rgb));
  --spectrum-global-color-static-magenta-600-rgb: 179, 15, 89;
  --spectrum-global-color-static-magenta-600: rgb(var(--spectrum-global-color-static-magenta-600-rgb));
  --spectrum-global-color-static-magenta-700-rgb: 149, 0, 72;
  --spectrum-global-color-static-magenta-700: rgb(var(--spectrum-global-color-static-magenta-700-rgb));
  --spectrum-global-color-static-magenta-800-rgb: 119, 0, 58;
  --spectrum-global-color-static-magenta-800: rgb(var(--spectrum-global-color-static-magenta-800-rgb));
  --spectrum-global-color-static-transparent-white-200: #ffffff1a;
  --spectrum-global-color-static-transparent-white-300: #ffffff40;
  --spectrum-global-color-static-transparent-white-400: #fff6;
  --spectrum-global-color-static-transparent-white-500: #ffffff8c;
  --spectrum-global-color-static-transparent-white-600: #ffffffb3;
  --spectrum-global-color-static-transparent-white-700: #fffc;
  --spectrum-global-color-static-transparent-white-800: #ffffffe6;
  --spectrum-global-color-static-transparent-white-900-rgb: 255, 255, 255;
  --spectrum-global-color-static-transparent-white-900: rgb(var(--spectrum-global-color-static-transparent-white-900-rgb));
  --spectrum-global-color-static-transparent-black-200: #0000001a;
  --spectrum-global-color-static-transparent-black-300: #00000040;
  --spectrum-global-color-static-transparent-black-400: #0006;
  --spectrum-global-color-static-transparent-black-500: #0000008c;
  --spectrum-global-color-static-transparent-black-600: #000000b3;
  --spectrum-global-color-static-transparent-black-700: #000c;
  --spectrum-global-color-static-transparent-black-800: #000000e6;
  --spectrum-global-color-static-transparent-black-900-rgb: 0, 0, 0;
  --spectrum-global-color-static-transparent-black-900: rgb(var(--spectrum-global-color-static-transparent-black-900-rgb));
  --spectrum-global-color-sequential-cerulean: #e9fff1, #c8f1e4, #a5e3d7, #82d5ca, #68c5c1, #54b4ba, #3fa2b2, #2991ac, #2280a2, #1f6d98, #1d5c8d, #1a4b83, #1a3979, #1a266f, #191264, #180057;
  --spectrum-global-color-sequential-forest: #ffffdf, #e2f6ba, #c4eb95, #a4e16d, #8dd366, #77c460, #5fb65a, #48a754, #36984f, #2c894d, #237a4a, #196b47, #105c45, #094d41, #033f3e, #00313a;
  --spectrum-global-color-sequential-rose: #fff4dd, #ffddd7, #ffc5d2, #feaecb, #fa96c4, #f57ebd, #ef64b5, #e846ad, #d238a1, #bb2e96, #a3248c, #8a1b83, #71167c, #560f74, #370b6e, #000968;
  --spectrum-global-color-diverging-orange-yellow-seafoam: #580000, #79260b, #9c4511, #bd651a, #dd8629, #f5ad52, #fed693, #ffffe0, #bbe4d1, #76c7be, #3ea8a6, #208288, #076769, #00494b, #002c2d;
  --spectrum-global-color-diverging-red-yellow-blue: #4a001e, #751232, #a52747, #c65154, #e47961, #f0a882, #fad4ac, #ffffe0, #bce2cf, #89c0c4, #579eb9, #397aa8, #1c5796, #163771, #10194d;
  --spectrum-global-color-diverging-red-blue: #4a001e, #731331, #9f2945, #cc415a, #e06e85, #ed9ab0, #f8c3d9, #faf0ff, #c6d0f2, #92b2de, #5d94cb, #2f74b3, #265191, #163670, #0b194c;
  --spectrum-semantic-negative-background-color: var(--spectrum-global-color-static-red-600);
  --spectrum-semantic-negative-color-default: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-color-hover: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-dark: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-border-color: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-icon-color: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-status-color: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-text-color-large: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-text-color-small: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-text-color-small-hover: var(--spectrum-global-color-red-700);
  --spectrum-semantic-negative-text-color-small-down: var(--spectrum-global-color-red-700);
  --spectrum-semantic-negative-text-color-small-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-down: var(--spectrum-global-color-red-700);
  --spectrum-semantic-negative-color-key-focus: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-background-color-default: var(--spectrum-global-color-static-red-600);
  --spectrum-semantic-negative-background-color-hover: var(--spectrum-global-color-static-red-700);
  --spectrum-semantic-negative-background-color-down: var(--spectrum-global-color-static-red-800);
  --spectrum-semantic-negative-background-color-key-focus: var(--spectrum-global-color-static-red-700);
  --spectrum-semantic-notice-background-color: var(--spectrum-global-color-static-orange-600);
  --spectrum-semantic-notice-color-default: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-color-dark: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-border-color: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-icon-color: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-status-color: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-text-color-large: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-text-color-small: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-down: var(--spectrum-global-color-orange-700);
  --spectrum-semantic-notice-color-key-focus: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-background-color-default: var(--spectrum-global-color-static-orange-600);
  --spectrum-semantic-notice-background-color-hover: var(--spectrum-global-color-static-orange-700);
  --spectrum-semantic-notice-background-color-down: var(--spectrum-global-color-static-orange-800);
  --spectrum-semantic-notice-background-color-key-focus: var(--spectrum-global-color-static-orange-700);
  --spectrum-semantic-positive-background-color: var(--spectrum-global-color-static-green-600);
  --spectrum-semantic-positive-color-default: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-color-dark: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-border-color: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-icon-color: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-status-color: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-text-color-large: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-text-color-small: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-down: var(--spectrum-global-color-green-700);
  --spectrum-semantic-positive-color-key-focus: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-background-color-default: var(--spectrum-global-color-static-green-600);
  --spectrum-semantic-positive-background-color-hover: var(--spectrum-global-color-static-green-700);
  --spectrum-semantic-positive-background-color-down: var(--spectrum-global-color-static-green-800);
  --spectrum-semantic-positive-background-color-key-focus: var(--spectrum-global-color-static-green-700);
  --spectrum-semantic-informative-background-color: var(--spectrum-global-color-static-blue-600);
  --spectrum-semantic-informative-color-default: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-color-dark: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-border-color: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-icon-color: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-status-color: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-text-color-large: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-text-color-small: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-semantic-informative-color-key-focus: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-background-color-default: var(--spectrum-global-color-static-blue-600);
  --spectrum-semantic-informative-background-color-hover: var(--spectrum-global-color-static-blue-700);
  --spectrum-semantic-informative-background-color-down: var(--spectrum-global-color-static-blue-800);
  --spectrum-semantic-informative-background-color-key-focus: var(--spectrum-global-color-static-blue-700);
  --spectrum-semantic-cta-background-color-default: var(--spectrum-global-color-static-blue-600);
  --spectrum-semantic-cta-background-color-hover: var(--spectrum-global-color-static-blue-700);
  --spectrum-semantic-cta-background-color-down: var(--spectrum-global-color-static-blue-800);
  --spectrum-semantic-cta-background-color-key-focus: var(--spectrum-global-color-static-blue-700);
  --spectrum-semantic-emphasized-border-color-default: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-emphasized-border-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-emphasized-border-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-semantic-emphasized-border-color-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-neutral-background-color-default: var(--spectrum-global-color-static-gray-700);
  --spectrum-semantic-neutral-background-color-hover: var(--spectrum-global-color-static-gray-800);
  --spectrum-semantic-neutral-background-color-down: var(--spectrum-global-color-static-gray-900);
  --spectrum-semantic-neutral-background-color-key-focus: var(--spectrum-global-color-static-gray-800);
  --spectrum-semantic-presence-color-1: var(--spectrum-global-color-static-red-500);
  --spectrum-semantic-presence-color-2: var(--spectrum-global-color-static-orange-400);
  --spectrum-semantic-presence-color-3: var(--spectrum-global-color-static-yellow-400);
  --spectrum-semantic-presence-color-4-rgb: 75, 204, 162;
  --spectrum-semantic-presence-color-4: rgb(var(--spectrum-semantic-presence-color-4-rgb));
  --spectrum-semantic-presence-color-5-rgb: 0, 199, 255;
  --spectrum-semantic-presence-color-5: rgb(var(--spectrum-semantic-presence-color-5-rgb));
  --spectrum-semantic-presence-color-6-rgb: 0, 140, 184;
  --spectrum-semantic-presence-color-6: rgb(var(--spectrum-semantic-presence-color-6-rgb));
  --spectrum-semantic-presence-color-7-rgb: 126, 75, 243;
  --spectrum-semantic-presence-color-7: rgb(var(--spectrum-semantic-presence-color-7-rgb));
  --spectrum-semantic-presence-color-8: var(--spectrum-global-color-static-fuchsia-600);
  --spectrum-global-dimension-static-percent-50: 50%;
  --spectrum-global-dimension-static-percent-70: 70%;
  --spectrum-global-dimension-static-percent-100: 100%;
  --spectrum-global-dimension-static-breakpoint-xsmall: 304px;
  --spectrum-global-dimension-static-breakpoint-small: 768px;
  --spectrum-global-dimension-static-breakpoint-medium: 1280px;
  --spectrum-global-dimension-static-breakpoint-large: 1768px;
  --spectrum-global-dimension-static-breakpoint-xlarge: 2160px;
  --spectrum-global-dimension-static-grid-columns: 12;
  --spectrum-global-dimension-static-grid-fluid-width: 100%;
  --spectrum-global-dimension-static-grid-fixed-max-width: 1280px;
  --spectrum-global-dimension-static-size-0: 0px;
  --spectrum-global-dimension-static-size-10: 1px;
  --spectrum-global-dimension-static-size-25: 2px;
  --spectrum-global-dimension-static-size-40: 3px;
  --spectrum-global-dimension-static-size-50: 4px;
  --spectrum-global-dimension-static-size-65: 5px;
  --spectrum-global-dimension-static-size-75: 6px;
  --spectrum-global-dimension-static-size-85: 7px;
  --spectrum-global-dimension-static-size-100: 8px;
  --spectrum-global-dimension-static-size-115: 9px;
  --spectrum-global-dimension-static-size-125: 10px;
  --spectrum-global-dimension-static-size-130: 11px;
  --spectrum-global-dimension-static-size-150: 12px;
  --spectrum-global-dimension-static-size-160: 13px;
  --spectrum-global-dimension-static-size-175: 14px;
  --spectrum-global-dimension-static-size-185: 15px;
  --spectrum-global-dimension-static-size-200: 16px;
  --spectrum-global-dimension-static-size-225: 18px;
  --spectrum-global-dimension-static-size-250: 20px;
  --spectrum-global-dimension-static-size-275: 22px;
  --spectrum-global-dimension-static-size-300: 24px;
  --spectrum-global-dimension-static-size-325: 26px;
  --spectrum-global-dimension-static-size-350: 28px;
  --spectrum-global-dimension-static-size-400: 32px;
  --spectrum-global-dimension-static-size-450: 36px;
  --spectrum-global-dimension-static-size-500: 40px;
  --spectrum-global-dimension-static-size-550: 44px;
  --spectrum-global-dimension-static-size-600: 48px;
  --spectrum-global-dimension-static-size-700: 56px;
  --spectrum-global-dimension-static-size-800: 64px;
  --spectrum-global-dimension-static-size-900: 72px;
  --spectrum-global-dimension-static-size-1000: 80px;
  --spectrum-global-dimension-static-size-1200: 96px;
  --spectrum-global-dimension-static-size-1700: 136px;
  --spectrum-global-dimension-static-size-2400: 192px;
  --spectrum-global-dimension-static-size-2500: 200px;
  --spectrum-global-dimension-static-size-2600: 208px;
  --spectrum-global-dimension-static-size-2800: 224px;
  --spectrum-global-dimension-static-size-3200: 256px;
  --spectrum-global-dimension-static-size-3400: 272px;
  --spectrum-global-dimension-static-size-3500: 280px;
  --spectrum-global-dimension-static-size-3600: 288px;
  --spectrum-global-dimension-static-size-3800: 304px;
  --spectrum-global-dimension-static-size-4600: 368px;
  --spectrum-global-dimension-static-size-5000: 400px;
  --spectrum-global-dimension-static-size-6000: 480px;
  --spectrum-global-dimension-static-size-16000: 1280px;
  --spectrum-global-dimension-static-font-size-50: 11px;
  --spectrum-global-dimension-static-font-size-75: 12px;
  --spectrum-global-dimension-static-font-size-100: 14px;
  --spectrum-global-dimension-static-font-size-150: 15px;
  --spectrum-global-dimension-static-font-size-200: 16px;
  --spectrum-global-dimension-static-font-size-300: 18px;
  --spectrum-global-dimension-static-font-size-400: 20px;
  --spectrum-global-dimension-static-font-size-500: 22px;
  --spectrum-global-dimension-static-font-size-600: 25px;
  --spectrum-global-dimension-static-font-size-700: 28px;
  --spectrum-global-dimension-static-font-size-800: 32px;
  --spectrum-global-dimension-static-font-size-900: 36px;
  --spectrum-global-dimension-static-font-size-1000: 40px;
  --spectrum-global-font-family-base: adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-family-serif: adobe-clean-serif, "Source Serif Pro", Georgia, serif;
  --spectrum-global-font-family-code: "Source Code Pro", Monaco, monospace;
  --spectrum-global-font-weight-thin: 100;
  --spectrum-global-font-weight-ultra-light: 200;
  --spectrum-global-font-weight-light: 300;
  --spectrum-global-font-weight-regular: 400;
  --spectrum-global-font-weight-medium: 500;
  --spectrum-global-font-weight-semi-bold: 600;
  --spectrum-global-font-weight-bold: 700;
  --spectrum-global-font-weight-extra-bold: 800;
  --spectrum-global-font-weight-black: 900;
  --spectrum-global-font-style-regular: normal;
  --spectrum-global-font-style-italic: italic;
  --spectrum-global-font-letter-spacing-none: 0;
  --spectrum-global-font-letter-spacing-small: .0125em;
  --spectrum-global-font-letter-spacing-han: .05em;
  --spectrum-global-font-letter-spacing-medium: .06em;
  --spectrum-global-font-line-height-large: 1.7;
  --spectrum-global-font-line-height-medium: 1.5;
  --spectrum-global-font-line-height-small: 1.3;
  --spectrum-global-font-multiplier-0: 0em;
  --spectrum-global-font-multiplier-25: .25em;
  --spectrum-global-font-multiplier-75: .75em;
  --spectrum-global-font-font-family-ar: myriad-arabic, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-font-family-he: myriad-hebrew, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-font-family-zh: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", "sans-serif";
  --spectrum-global-font-font-family-zhhans: adobe-clean-han-simplified-c, source-han-simplified-c, "SimSun", "Heiti SC Light", "sans-serif";
  --spectrum-global-font-font-family-ko: adobe-clean-han-korean, source-han-korean, "Malgun Gothic", "Apple Gothic", "sans-serif";
  --spectrum-global-font-font-family-ja: adobe-clean-han-japanese, "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Osaka", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  --spectrum-global-font-font-family-condensed: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-loupe-entry-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-alias-loupe-exit-animation-duration: var(--spectrum-global-animation-duration-300);
  --spectrum-alias-heading-text-line-height: var(--spectrum-global-font-line-height-small);
  --spectrum-alias-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-heading-text-font-weight-light: var(--spectrum-global-font-weight-light);
  --spectrum-alias-heading-text-font-weight-light-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-heading-text-font-weight-heavy: var(--spectrum-global-font-weight-black);
  --spectrum-alias-heading-text-font-weight-heavy-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light);
  --spectrum-alias-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-heading-margin-bottom: var(--spectrum-global-font-multiplier-25);
  --spectrum-alias-subheading-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-body-text-font-family: var(--spectrum-global-font-family-base);
  --spectrum-alias-body-text-line-height: var(--spectrum-global-font-line-height-medium);
  --spectrum-alias-body-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-body-text-font-weight-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-body-margin-bottom: var(--spectrum-global-font-multiplier-75);
  --spectrum-alias-detail-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-detail-text-font-weight-regular: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-detail-text-font-weight-light: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-article-heading-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-article-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-article-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-article-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-article-body-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-article-body-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-article-subheading-text-font-weight: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-article-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-article-detail-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-article-detail-text-font-weight-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-code-text-font-family: var(--spectrum-global-font-family-code);
  --spectrum-alias-code-text-font-weight-regular: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-code-text-font-weight-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-code-text-line-height: var(--spectrum-global-font-line-height-medium);
  --spectrum-alias-code-margin-bottom: var(--spectrum-global-font-multiplier-0);
  --spectrum-alias-font-family-ar: var(--spectrum-global-font-font-family-ar);
  --spectrum-alias-font-family-he: var(--spectrum-global-font-font-family-he);
  --spectrum-alias-font-family-zh: var(--spectrum-global-font-font-family-zh);
  --spectrum-alias-font-family-zhhans: var(--spectrum-global-font-font-family-zhhans);
  --spectrum-alias-font-family-ko: var(--spectrum-global-font-font-family-ko);
  --spectrum-alias-font-family-ja: var(--spectrum-global-font-font-family-ja);
  --spectrum-alias-font-family-condensed: var(--spectrum-global-font-font-family-condensed);
  --spectrum-alias-button-text-line-height: var(--spectrum-global-font-line-height-small);
  --spectrum-alias-component-text-line-height: var(--spectrum-global-font-line-height-small);
  --spectrum-alias-han-component-text-line-height: var(--spectrum-global-font-line-height-medium);
  --spectrum-alias-serif-text-font-family: var(--spectrum-global-font-family-serif);
  --spectrum-alias-han-heading-text-line-height: var(--spectrum-global-font-line-height-medium);
  --spectrum-alias-han-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-heading-text-font-weight-regular-emphasis: var(--spectrum-global-font-weight-extra-bold);
  --spectrum-alias-han-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-heading-text-font-weight-light: var(--spectrum-global-font-weight-light);
  --spectrum-alias-han-heading-text-font-weight-light-emphasis: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-han-heading-text-font-weight-light-strong: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-heading-text-font-weight-heavy: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-heading-text-font-weight-heavy-emphasis: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-heading-text-font-weight-heavy-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-body-text-line-height: var(--spectrum-global-font-line-height-large);
  --spectrum-alias-han-body-text-font-weight-regular: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-han-body-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-body-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-subheading-text-font-weight-regular: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-subheading-text-font-weight-emphasis: var(--spectrum-global-font-weight-extra-bold);
  --spectrum-alias-han-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-han-detail-text-font-weight: var(--spectrum-global-font-weight-regular);
  --spectrum-alias-han-detail-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold);
  --spectrum-alias-han-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black);
  --spectrum-alias-item-height-s: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-item-height-m: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-item-height-l: var(--spectrum-global-dimension-size-500);
  --spectrum-alias-item-height-xl: var(--spectrum-global-dimension-size-600);
  --spectrum-alias-item-rounded-border-radius-s: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-rounded-border-radius-m: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-item-rounded-border-radius-l: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-item-rounded-border-radius-xl: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-item-text-size-s: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-item-text-size-m: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-item-text-size-l: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-item-text-size-xl: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-item-text-padding-top-s: var(--spectrum-global-dimension-static-size-50);
  --spectrum-alias-item-text-padding-top-m: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-text-padding-top-xl: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-text-padding-bottom-m: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-text-padding-bottom-l: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-item-text-padding-bottom-xl: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-item-icon-padding-top-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-item-icon-padding-top-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-icon-padding-top-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-icon-padding-top-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-icon-padding-bottom-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-item-icon-padding-bottom-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-icon-padding-bottom-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-icon-padding-bottom-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-padding-s: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-padding-m: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-padding-l: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-item-padding-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-item-rounded-padding-s: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-rounded-padding-m: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-item-rounded-padding-l: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-item-rounded-padding-xl: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-item-icononly-padding-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-item-icononly-padding-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-icononly-padding-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-icononly-padding-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-control-gap-s: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-control-gap-m: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-control-gap-l: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-item-control-gap-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-workflow-icon-gap-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-workflow-icon-gap-m: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-workflow-icon-gap-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-workflow-icon-gap-xl: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-mark-gap-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-mark-gap-m: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-mark-gap-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-mark-gap-xl: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-ui-icon-gap-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-ui-icon-gap-m: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-ui-icon-gap-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-ui-icon-gap-xl: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-clearbutton-gap-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-item-clearbutton-gap-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-clearbutton-gap-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-clearbutton-gap-xl: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-workflow-padding-left-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-workflow-padding-left-l: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-workflow-padding-left-xl: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-item-rounded-workflow-padding-left-s: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-rounded-workflow-padding-left-l: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-item-mark-padding-top-s: var(--spectrum-global-dimension-size-40);
  --spectrum-alias-item-mark-padding-top-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-mark-padding-top-xl: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-item-mark-padding-bottom-s: var(--spectrum-global-dimension-size-40);
  --spectrum-alias-item-mark-padding-bottom-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-mark-padding-bottom-xl: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-item-mark-padding-left-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-mark-padding-left-l: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-item-mark-padding-left-xl: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-item-control-1-size-s: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alias-item-control-1-size-m: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-control-2-size-m: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-item-control-2-size-l: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-item-control-2-size-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-item-control-2-size-xxl: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-item-control-2-border-radius-s: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-control-2-border-radius-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-control-2-border-radius-l: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-control-2-border-radius-xl: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-control-2-border-radius-xxl: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-item-control-2-padding-s: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-control-2-padding-m: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-control-2-padding-l: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-control-2-padding-xl: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-item-control-3-height-m: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-item-control-3-height-l: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-item-control-3-height-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-item-control-3-border-radius-s: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-control-3-border-radius-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-item-control-3-border-radius-l: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-item-control-3-border-radius-xl: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-control-3-padding-s: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-item-control-3-padding-m: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-item-control-3-padding-l: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-item-control-3-padding-xl: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-item-mark-size-s: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-item-mark-size-l: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-item-mark-size-xl: var(--spectrum-global-dimension-size-325);
  --spectrum-alias-heading-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-xxl-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-xl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-l-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-m-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-s-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-xs-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-xxs-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-xxl-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-l-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-m-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-s-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-xs-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xxs-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-heading-han-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-xxl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-xl-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-l-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-han-m-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-han-s-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-han-xs-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-han-xxs-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-han-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-han-xxl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xl-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-han-l-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-han-m-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-han-s-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-han-xs-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-han-xxs-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-component-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-component-border-radius-quiet: var(--spectrum-global-dimension-static-size-0);
  --spectrum-alias-component-focusring-gap: var(--spectrum-global-dimension-static-size-0);
  --spectrum-alias-component-focusring-gap-emphasized: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-component-focusring-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-component-focusring-size-emphasized: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-input-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-input-focusring-gap: var(--spectrum-global-dimension-static-size-0);
  --spectrum-alias-input-quiet-focusline-gap: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-control-two-size-m: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-control-two-size-l: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-control-two-size-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-control-two-size-xxl: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-control-two-border-radius-s: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-control-two-border-radius-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-control-two-border-radius-l: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-control-two-border-radius-xl: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-control-two-border-radius-xxl: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-control-two-focus-ring-border-radius-s: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-control-two-focus-ring-border-radius-m: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-control-two-focus-ring-border-radius-l: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-control-two-focus-ring-border-radius-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-control-two-focus-ring-border-radius-xxl: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-control-three-height-m: var(--spectrum-global-dimension-size-175);
  --spectrum-alias-control-three-height-l: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-control-three-height-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-infieldbutton-icon-margin-y-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-infieldbutton-icon-margin-y-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-infieldbutton-icon-margin-y-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-infieldbutton-icon-margin-y-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-infieldbutton-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-infieldbutton-border-radius-sided: 0;
  --spectrum-alias-infieldbutton-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-infieldbutton-fill-padding-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-infieldbutton-fill-padding-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-infieldbutton-fill-padding-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-infieldbutton-fill-padding-xl: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-infieldbutton-padding-s: 0;
  --spectrum-alias-infieldbutton-padding-m: 0;
  --spectrum-alias-infieldbutton-padding-l: 0;
  --spectrum-alias-infieldbutton-padding-xl: 0;
  --spectrum-alias-infieldbutton-full-height-s: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-infieldbutton-full-height-m: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-infieldbutton-full-height-l: var(--spectrum-global-dimension-size-500);
  --spectrum-alias-infieldbutton-full-height-xl: var(--spectrum-global-dimension-size-600);
  --spectrum-alias-infieldbutton-half-height-s: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-infieldbutton-half-height-m: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-infieldbutton-half-height-l: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-infieldbutton-half-height-xl: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-stepperbutton-gap: 0;
  --spectrum-alias-stepperbutton-width-s: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-stepperbutton-width-m: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-stepperbutton-width-l: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-stepperbutton-width-xl: var(--spectrum-global-dimension-size-450);
  --spectrum-alias-stepperbutton-icon-x-offset-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-stepperbutton-icon-x-offset-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-stepperbutton-icon-x-offset-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-stepperbutton-icon-x-offset-xl: var(--spectrum-global-dimension-size-130);
  --spectrum-alias-stepperbutton-icon-y-offset-top-s: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-stepperbutton-icon-y-offset-top-m: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-stepperbutton-icon-y-offset-top-l: var(--spectrum-global-dimension-size-65);
  --spectrum-alias-stepperbutton-icon-y-offset-top-xl: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-stepperbutton-icon-y-offset-bottom-s: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-stepperbutton-icon-y-offset-bottom-m: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-stepperbutton-icon-y-offset-bottom-l: var(--spectrum-global-dimension-size-40);
  --spectrum-alias-stepperbutton-icon-y-offset-bottom-xl: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-stepperbutton-radius-touching: 0;
  --spectrum-alias-clearbutton-icon-margin-s: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-clearbutton-icon-margin-m: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-clearbutton-icon-margin-l: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-clearbutton-icon-margin-xl: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-clearbutton-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-pickerbutton-icononly-padding-x-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-pickerbutton-icononly-padding-x-m: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-pickerbutton-icononly-padding-x-l: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-pickerbutton-icononly-padding-x-xl: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-pickerbutton-icon-margin-y-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-pickerbutton-icon-margin-y-m: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-pickerbutton-icon-margin-y-l: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-pickerbutton-icon-margin-y-xl: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-pickerbutton-label-padding-y-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-pickerbutton-label-padding-y-m: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-pickerbutton-label-padding-y-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-pickerbutton-label-padding-y-xl: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-pickerbutton-border-radius-rounded: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-pickerbutton-border-radius-rounded-sided: 0;
  --spectrum-alias-search-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-search-border-radius-quiet: 0;
  --spectrum-alias-combobox-quiet-button-offset-x: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-thumbnail-border-radius-small: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-search-padding-left-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-search-padding-left-l: var(--spectrum-global-dimension-size-160);
  --spectrum-alias-search-padding-left-xl: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-percent-50: 50%;
  --spectrum-alias-percent-70: 70%;
  --spectrum-alias-percent-100: 100%;
  --spectrum-alias-breakpoint-xsmall: 304px;
  --spectrum-alias-breakpoint-small: 768px;
  --spectrum-alias-breakpoint-medium: 1280px;
  --spectrum-alias-breakpoint-large: 1768px;
  --spectrum-alias-breakpoint-xlarge: 2160px;
  --spectrum-alias-grid-columns: 12;
  --spectrum-alias-grid-fluid-width: 100%;
  --spectrum-alias-grid-fixed-max-width: 1280px;
  --spectrum-alias-border-size-thin: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-border-size-thick: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-border-size-thicker: var(--spectrum-global-dimension-static-size-50);
  --spectrum-alias-border-size-thickest: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alias-border-offset-thin: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-border-offset-thick: var(--spectrum-global-dimension-static-size-50);
  --spectrum-alias-border-offset-thicker: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alias-border-offset-thickest: var(--spectrum-global-dimension-static-size-200);
  --spectrum-alias-grid-baseline: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alias-grid-gutter-xsmall: var(--spectrum-global-dimension-static-size-200);
  --spectrum-alias-grid-gutter-small: var(--spectrum-global-dimension-static-size-300);
  --spectrum-alias-grid-gutter-medium: var(--spectrum-global-dimension-static-size-400);
  --spectrum-alias-grid-gutter-large: var(--spectrum-global-dimension-static-size-500);
  --spectrum-alias-grid-gutter-xlarge: var(--spectrum-global-dimension-static-size-600);
  --spectrum-alias-grid-margin-xsmall: var(--spectrum-global-dimension-static-size-200);
  --spectrum-alias-grid-margin-small: var(--spectrum-global-dimension-static-size-300);
  --spectrum-alias-grid-margin-medium: var(--spectrum-global-dimension-static-size-400);
  --spectrum-alias-grid-margin-large: var(--spectrum-global-dimension-static-size-500);
  --spectrum-alias-grid-margin-xlarge: var(--spectrum-global-dimension-static-size-600);
  --spectrum-alias-grid-layout-region-margin-bottom-xsmall: var(--spectrum-global-dimension-static-size-200);
  --spectrum-alias-grid-layout-region-margin-bottom-small: var(--spectrum-global-dimension-static-size-300);
  --spectrum-alias-grid-layout-region-margin-bottom-medium: var(--spectrum-global-dimension-static-size-400);
  --spectrum-alias-grid-layout-region-margin-bottom-large: var(--spectrum-global-dimension-static-size-500);
  --spectrum-alias-grid-layout-region-margin-bottom-xlarge: var(--spectrum-global-dimension-static-size-600);
  --spectrum-alias-radial-reaction-size-default: var(--spectrum-global-dimension-static-size-550);
  --spectrum-alias-focus-ring-gap: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-focus-ring-size: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-focus-ring-gap-small: var(--spectrum-global-dimension-static-size-0);
  --spectrum-alias-focus-ring-size-small: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-dropshadow-blur: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-dropshadow-offset-y: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-font-size-default: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-layout-label-gap-size: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-pill-button-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-pill-button-text-baseline: var(--spectrum-global-dimension-static-size-150);
  --spectrum-alias-border-radius-xsmall: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-border-radius-small: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-border-radius-regular: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-border-radius-medium: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-border-radius-large: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-border-radius-xlarge: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-focus-ring-border-radius-xsmall: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-focus-ring-border-radius-small: var(--spectrum-global-dimension-static-size-65);
  --spectrum-alias-focus-ring-border-radius-medium: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-focus-ring-border-radius-large: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-focus-ring-border-radius-xlarge: var(--spectrum-global-dimension-size-350);
  --spectrum-alias-single-line-height: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-single-line-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-alias-workflow-icon-size-s: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-workflow-icon-size-m: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-workflow-icon-size-xl: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-ui-icon-alert-size-75: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-ui-icon-alert-size-100: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-ui-icon-alert-size-200: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-ui-icon-alert-size-300: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-ui-icon-triplegripper-size-100-height: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-ui-icon-doublegripper-size-100-width: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-ui-icon-singlegripper-size-100-width: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-ui-icon-cornertriangle-size-75: var(--spectrum-global-dimension-size-65);
  --spectrum-alias-ui-icon-cornertriangle-size-200: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-ui-icon-asterisk-size-75: var(--spectrum-global-dimension-static-size-100);
  --spectrum-alias-ui-icon-asterisk-size-100: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-avatar-size-50: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-avatar-size-75: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-avatar-size-200: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-avatar-size-300: var(--spectrum-global-dimension-size-325);
  --spectrum-alias-avatar-size-500: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-avatar-size-700: var(--spectrum-global-dimension-size-500);
  --spectrum-alias-avatar-border-size: var(--spectrum-global-dimension-size-0);
  --spectrum-alias-tag-border-radius: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-tag-border-size-default: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-tag-border-size-key-focus: var(--spectrum-global-dimension-static-size-25);
  --spectrum-alias-tag-border-size-disabled: var(--spectrum-global-dimension-size-0);
  --spectrum-alias-tag-border-size: var(--spectrum-global-dimension-static-size-10);
  --spectrum-alias-tag-padding-right-s: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-tag-padding-right-m: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-tag-padding-right-l: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-tag-height-s: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-tag-height-m: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-tag-height-l: var(--spectrum-global-dimension-size-500);
  --spectrum-alias-tag-font-size-s: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-tag-font-size-m: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-tag-font-size-l: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-tag-text-padding-top-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-tag-text-padding-top-m: var(--spectrum-global-dimension-size-75);
  --spectrum-alias-tag-text-padding-top-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-tag-icon-size-s: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-tag-icon-size-m: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-tag-icon-margin-top-s: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-tag-icon-margin-top-m: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-tag-icon-margin-top-l: var(--spectrum-global-dimension-size-125);
  --spectrum-alias-tag-icon-margin-right-s: var(--spectrum-global-dimension-size-85);
  --spectrum-alias-tag-icon-margin-right-m: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-tag-icon-margin-right-l: var(--spectrum-global-dimension-size-115);
  --spectrum-alias-tag-clearbutton-width-s: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-tag-clearbutton-width-m: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-tag-clearbutton-width-l: var(--spectrum-global-dimension-size-500);
  --spectrum-alias-tag-clearbutton-icon-margin-s: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-tag-clearbutton-icon-margin-m: var(--spectrum-global-dimension-size-150);
  --spectrum-alias-tag-clearbutton-icon-margin-l: var(--spectrum-global-dimension-size-185);
  --spectrum-alias-tag-focusring-size: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-tag-focusring-gap: var(--spectrum-global-dimension-static-size-0);
  --spectrum-alias-tag-focusring-gap-selected: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-colorloupe-width: var(--spectrum-global-dimension-static-size-600);
  --spectrum-alias-colorloupe-height: var(--spectrum-global-dimension-static-size-800);
  --spectrum-alias-colorhandle-outer-border-color: #0000006b;
  --spectrum-alias-transparent-blue-background-color-hover: #0057be26;
  --spectrum-alias-transparent-blue-background-color-down: #0048994d;
  --spectrum-alias-transparent-blue-background-color-key-focus: var(--spectrum-alias-transparent-blue-background-color-hover);
  --spectrum-alias-transparent-blue-background-color-mouse-focus: var(--spectrum-alias-transparent-blue-background-color-hover);
  --spectrum-alias-transparent-blue-background-color: var(--spectrum-alias-component-text-color-default);
  --spectrum-alias-transparent-red-background-color-hover: #9a000026;
  --spectrum-alias-transparent-red-background-color-down: #7c00004d;
  --spectrum-alias-transparent-red-background-color-key-focus: var(--spectrum-alias-transparent-red-background-color-hover);
  --spectrum-alias-transparent-red-background-color-mouse-focus: var(--spectrum-alias-transparent-red-background-color-hover);
  --spectrum-alias-transparent-red-background-color: var(--spectrum-alias-component-text-color-default);
  --spectrum-alias-component-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-alias-component-text-color-default: var(--spectrum-global-color-gray-800);
  --spectrum-alias-component-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-component-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-component-text-color-key-focus: var(--spectrum-alias-component-text-color-hover);
  --spectrum-alias-component-text-color-mouse-focus: var(--spectrum-alias-component-text-color-hover);
  --spectrum-alias-component-text-color: var(--spectrum-alias-component-text-color-default);
  --spectrum-alias-component-text-color-selected-default: var(--spectrum-alias-component-text-color-default);
  --spectrum-alias-component-text-color-selected-hover: var(--spectrum-alias-component-text-color-hover);
  --spectrum-alias-component-text-color-selected-down: var(--spectrum-alias-component-text-color-down);
  --spectrum-alias-component-text-color-selected-key-focus: var(--spectrum-alias-component-text-color-key-focus);
  --spectrum-alias-component-text-color-selected-mouse-focus: var(--spectrum-alias-component-text-color-mouse-focus);
  --spectrum-alias-component-text-color-selected: var(--spectrum-alias-component-text-color-selected-default);
  --spectrum-alias-component-text-color-emphasized-selected-default: var(--spectrum-global-color-static-white);
  --spectrum-alias-component-text-color-emphasized-selected-hover: var(--spectrum-alias-component-text-color-emphasized-selected-default);
  --spectrum-alias-component-text-color-emphasized-selected-down: var(--spectrum-alias-component-text-color-emphasized-selected-default);
  --spectrum-alias-component-text-color-emphasized-selected-key-focus: var(--spectrum-alias-component-text-color-emphasized-selected-default);
  --spectrum-alias-component-text-color-emphasized-selected-mouse-focus: var(--spectrum-alias-component-text-color-emphasized-selected-default);
  --spectrum-alias-component-text-color-emphasized-selected: var(--spectrum-alias-component-text-color-emphasized-selected-default);
  --spectrum-alias-component-text-color-error-default: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-component-text-color-error-hover: var(--spectrum-semantic-negative-text-color-small-hover);
  --spectrum-alias-component-text-color-error-down: var(--spectrum-semantic-negative-text-color-small-down);
  --spectrum-alias-component-text-color-error-key-focus: var(--spectrum-semantic-negative-text-color-small-key-focus);
  --spectrum-alias-component-text-color-error-mouse-focus: var(--spectrum-semantic-negative-text-color-small-key-focus);
  --spectrum-alias-component-text-color-error: var(--spectrum-alias-component-text-color-error-default);
  --spectrum-alias-component-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-alias-component-icon-color-default: var(--spectrum-alias-icon-color);
  --spectrum-alias-component-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-component-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-alias-component-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-component-icon-color-mouse-focus: var(--spectrum-alias-icon-color-down);
  --spectrum-alias-component-icon-color: var(--spectrum-alias-component-icon-color-default);
  --spectrum-alias-component-icon-color-selected: var(--spectrum-alias-icon-color-selected-neutral-subdued);
  --spectrum-alias-component-icon-color-emphasized-selected-default: var(--spectrum-global-color-static-white);
  --spectrum-alias-component-icon-color-emphasized-selected-hover: var(--spectrum-alias-component-icon-color-emphasized-selected-default);
  --spectrum-alias-component-icon-color-emphasized-selected-down: var(--spectrum-alias-component-icon-color-emphasized-selected-default);
  --spectrum-alias-component-icon-color-emphasized-selected-key-focus: var(--spectrum-alias-component-icon-color-emphasized-selected-default);
  --spectrum-alias-component-icon-color-emphasized-selected: var(--spectrum-alias-component-icon-color-emphasized-selected-default);
  --spectrum-alias-component-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-quiet-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-component-background-color-quiet-selected-disabled: var(--spectrum-alias-component-background-color-disabled);
  --spectrum-alias-component-background-color-default: var(--spectrum-global-color-gray-75);
  --spectrum-alias-component-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-component-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-component-background-color: var(--spectrum-alias-component-background-color-default);
  --spectrum-alias-component-background-color-selected-default: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-selected-hover: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-selected-down: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-selected-key-focus: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-background-color-selected: var(--spectrum-alias-component-background-color-selected-default);
  --spectrum-alias-component-background-color-quiet-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-component-background-color-quiet-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-component-background-color-quiet-down: var(--spectrum-global-color-gray-300);
  --spectrum-alias-component-background-color-quiet-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-component-background-color-quiet: var(--spectrum-alias-component-background-color-quiet-default);
  --spectrum-alias-component-background-color-quiet-selected-default: var(--spectrum-alias-component-background-color-selected-default);
  --spectrum-alias-component-background-color-quiet-selected-hover: var(--spectrum-alias-component-background-color-selected-hover);
  --spectrum-alias-component-background-color-quiet-selected-down: var(--spectrum-alias-component-background-color-selected-down);
  --spectrum-alias-component-background-color-quiet-selected-key-focus: var(--spectrum-alias-component-background-color-selected-key-focus);
  --spectrum-alias-component-background-color-quiet-selected: var(--spectrum-alias-component-background-color-selected-default);
  --spectrum-alias-component-background-color-emphasized-selected-default: var(--spectrum-semantic-cta-background-color-default);
  --spectrum-alias-component-background-color-emphasized-selected-hover: var(--spectrum-semantic-cta-background-color-hover);
  --spectrum-alias-component-background-color-emphasized-selected-down: var(--spectrum-semantic-cta-background-color-down);
  --spectrum-alias-component-background-color-emphasized-selected-key-focus: var(--spectrum-semantic-cta-background-color-key-focus);
  --spectrum-alias-component-background-color-emphasized-selected: var(--spectrum-alias-component-background-color-emphasized-selected-default);
  --spectrum-alias-component-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-alias-component-border-color-quiet-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-alias-component-border-color-default: var(--spectrum-alias-border-color);
  --spectrum-alias-component-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-alias-component-border-color-down: var(--spectrum-alias-border-color-down);
  --spectrum-alias-component-border-color-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-component-border-color: var(--spectrum-alias-component-border-color-default);
  --spectrum-alias-component-border-color-selected-default: var(--spectrum-alias-border-color);
  --spectrum-alias-component-border-color-selected-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-alias-component-border-color-selected-down: var(--spectrum-alias-border-color-down);
  --spectrum-alias-component-border-color-selected-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-component-border-color-selected: var(--spectrum-alias-component-border-color-selected-default);
  --spectrum-alias-component-border-color-quiet-default: var(--spectrum-alias-border-color-transparent);
  --spectrum-alias-component-border-color-quiet-hover: var(--spectrum-alias-border-color-transparent);
  --spectrum-alias-component-border-color-quiet-down: var(--spectrum-alias-border-color-transparent);
  --spectrum-alias-component-border-color-quiet-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-component-border-color-quiet: var(--spectrum-alias-component-border-color-quiet-default);
  --spectrum-alias-component-border-color-quiet-selected-default: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-border-color-quiet-selected-hover: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-border-color-quiet-selected-down: var(--spectrum-global-color-gray-200);
  --spectrum-alias-component-border-color-quiet-selected-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-component-border-color-quiet-selected: var(--spectrum-alias-component-border-color-quiet-selected-default);
  --spectrum-alias-component-border-color-emphasized-selected-default: var(--spectrum-semantic-cta-background-color-default);
  --spectrum-alias-component-border-color-emphasized-selected-hover: var(--spectrum-semantic-cta-background-color-hover);
  --spectrum-alias-component-border-color-emphasized-selected-down: var(--spectrum-semantic-cta-background-color-down);
  --spectrum-alias-component-border-color-emphasized-selected-key-focus: var(--spectrum-semantic-cta-background-color-key-focus);
  --spectrum-alias-component-border-color-emphasized-selected: var(--spectrum-alias-component-border-color-emphasized-selected-default);
  --spectrum-alias-tag-border-color-default: var(--spectrum-alias-border-color-darker-default);
  --spectrum-alias-tag-border-color-hover: var(--spectrum-alias-border-color-darker-hover);
  --spectrum-alias-tag-border-color-down: var(--spectrum-alias-border-color-darker-hover);
  --spectrum-alias-tag-border-color-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-tag-border-color-error-default: var(--spectrum-semantic-negative-color-default);
  --spectrum-alias-tag-border-color-error-hover: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-tag-border-color-error-down: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-tag-border-color-error-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-tag-border-color-error-selected: var(--spectrum-semantic-negative-color-default);
  --spectrum-alias-tag-border-color-selected: var(--spectrum-alias-tag-background-color-selected-default);
  --spectrum-alias-tag-border-color: var(--spectrum-alias-tag-border-color-default);
  --spectrum-alias-tag-border-color-disabled: var(--spectrum-alias-border-color-disabled);
  --spectrum-alias-tag-border-color-error: var(--spectrum-alias-tag-border-color-error-default);
  --spectrum-alias-tag-text-color-default: var(--spectrum-alias-label-text-color);
  --spectrum-alias-tag-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-alias-tag-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-alias-tag-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-alias-tag-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-alias-tag-text-color: var(--spectrum-alias-tag-text-color-default);
  --spectrum-alias-tag-text-color-error-default: var(--spectrum-global-color-red-600);
  --spectrum-alias-tag-text-color-error-hover: var(--spectrum-global-color-red-700);
  --spectrum-alias-tag-text-color-error-down: var(--spectrum-global-color-red-700);
  --spectrum-alias-tag-text-color-error-key-focus: var(--spectrum-global-color-red-700);
  --spectrum-alias-tag-text-color-error: var(--spectrum-alias-tag-text-color-error-default);
  --spectrum-alias-tag-text-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-icon-color-default: var(--spectrum-alias-icon-color);
  --spectrum-alias-tag-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-tag-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-alias-tag-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-tag-icon-color-disabled: var(--spectrum-alias-icon-color-disabled);
  --spectrum-alias-tag-icon-color: var(--spectrum-alias-tag-icon-color-default);
  --spectrum-alias-tag-icon-color-error: var(--spectrum-global-color-red-600);
  --spectrum-alias-tag-icon-color-selected: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-tag-background-color-default: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-down: var(--spectrum-global-color-gray-200);
  --spectrum-alias-tag-background-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color: var(--spectrum-alias-tag-background-color-default);
  --spectrum-alias-tag-background-color-error-default: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-error-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-error-down: var(--spectrum-global-color-gray-200);
  --spectrum-alias-tag-background-color-error-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-tag-background-color-error: var(--spectrum-alias-tag-background-color-error-default);
  --spectrum-alias-tag-background-color-error-selected-default: var(--spectrum-semantic-negative-color-default);
  --spectrum-alias-tag-background-color-error-selected-hover: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-tag-background-color-error-selected-down: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-tag-background-color-error-selected-key-focus: var(--spectrum-global-color-red-600);
  --spectrum-alias-tag-background-color-error-selected: var(--spectrum-alias-tag-background-color-error-selected-default);
  --spectrum-alias-tag-background-color-selected-default: var(--spectrum-global-color-gray-700);
  --spectrum-alias-tag-background-color-selected-hover: var(--spectrum-global-color-gray-800);
  --spectrum-alias-tag-background-color-selected-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-tag-background-color-selected-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-alias-tag-background-color-selected: var(--spectrum-alias-tag-background-color-selected-default);
  --spectrum-alias-tag-focusring-border-color-default: transparent;
  --spectrum-alias-tag-focusring-border-color-key-focus: transparent;
  --spectrum-alias-tag-focusring-border-color-disabled: transparent;
  --spectrum-alias-tag-focusring-border-color-selected-key-focus: var(--spectrum-alias-focus-ring-color);
  --spectrum-alias-tag-focusring-border-color: var(--spectrum-alias-tag-focusring-border-color-default);
  --spectrum-alias-avatar-border-color-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color: var(--spectrum-alias-avatar-border-color-default);
  --spectrum-alias-avatar-border-color-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-selected-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-selected-hover: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-selected-down: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-selected-key-focus: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-avatar-border-color-selected: var(--spectrum-alias-avatar-border-color-selected-default);
  --spectrum-alias-avatar-border-color-selected-disabled: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-toggle-background-color-default: var(--spectrum-global-color-gray-700);
  --spectrum-alias-toggle-background-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-alias-toggle-background-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-toggle-background-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-alias-toggle-background-color: var(--spectrum-alias-toggle-background-color-default);
  --spectrum-alias-toggle-background-color-emphasized-selected-default: var(--spectrum-global-color-blue-500);
  --spectrum-alias-toggle-background-color-emphasized-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-alias-toggle-background-color-emphasized-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-alias-toggle-background-color-emphasized-selected-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-toggle-background-color-emphasized-selected: var(--spectrum-alias-toggle-background-color-emphasized-selected-default);
  --spectrum-alias-toggle-border-color-default: var(--spectrum-global-color-gray-700);
  --spectrum-alias-toggle-border-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-alias-toggle-border-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-toggle-border-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-alias-toggle-border-color: var(--spectrum-alias-toggle-border-color-default);
  --spectrum-alias-toggle-icon-color-selected: var(--spectrum-global-color-gray-75);
  --spectrum-alias-toggle-icon-color-emphasized-selected: var(--spectrum-global-color-gray-75);
  --spectrum-alias-button-primary-background-color-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-button-primary-background-color-hover: var(--spectrum-global-color-gray-800);
  --spectrum-alias-button-primary-background-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-button-primary-background-color-key-focus: var(--spectrum-global-color-gray-800);
  --spectrum-alias-button-primary-background-color: var(--spectrum-alias-button-primary-background-color-default);
  --spectrum-alias-button-primary-border-color-default: var(--spectrum-global-color-gray-800);
  --spectrum-alias-button-primary-border-color-hover: var(--spectrum-alias-button-primary-background-color-hover);
  --spectrum-alias-button-primary-border-color-down: var(--spectrum-alias-button-primary-background-color-down);
  --spectrum-alias-button-primary-border-color-key-focus: var(--spectrum-alias-button-primary-background-color-key-focus);
  --spectrum-alias-button-primary-border-color: var(--spectrum-alias-button-primary-border-color-default);
  --spectrum-alias-button-primary-text-color-default: var(--spectrum-global-color-gray-800);
  --spectrum-alias-button-primary-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-primary-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-primary-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-primary-text-color: var(--spectrum-alias-button-primary-text-color-default);
  --spectrum-alias-button-primary-icon-color-default: var(--spectrum-alias-button-primary-text-color-default);
  --spectrum-alias-button-primary-icon-color-hover: var(--spectrum-alias-button-primary-text-color-hover);
  --spectrum-alias-button-primary-icon-color-down: var(--spectrum-alias-button-primary-text-color-down);
  --spectrum-alias-button-primary-icon-color-key-focus: var(--spectrum-alias-button-primary-text-color-key-focus);
  --spectrum-alias-button-primary-icon-color: var(--spectrum-alias-button-primary-icon-color-default);
  --spectrum-alias-button-secondary-background-color-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-button-secondary-background-color-hover: var(--spectrum-global-color-gray-700);
  --spectrum-alias-button-secondary-background-color-down: var(--spectrum-global-color-gray-800);
  --spectrum-alias-button-secondary-background-color-key-focus: var(--spectrum-global-color-gray-700);
  --spectrum-alias-button-secondary-background-color: var(--spectrum-alias-button-secondary-background-color-default);
  --spectrum-alias-button-secondary-border-color-default: var(--spectrum-global-color-gray-700);
  --spectrum-alias-button-secondary-border-color-hover: var(--spectrum-alias-button-secondary-background-color-hover);
  --spectrum-alias-button-secondary-border-color-down: var(--spectrum-alias-button-secondary-background-color-down);
  --spectrum-alias-button-secondary-border-color-key-focus: var(--spectrum-alias-button-secondary-background-color-key-focus);
  --spectrum-alias-button-secondary-border-color: var(--spectrum-alias-button-secondary-border-color-default);
  --spectrum-alias-button-secondary-text-color-default: var(--spectrum-global-color-gray-700);
  --spectrum-alias-button-secondary-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-secondary-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-secondary-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-secondary-text-color: var(--spectrum-alias-button-secondary-text-color-default);
  --spectrum-alias-button-secondary-icon-color-default: var(--spectrum-alias-button-secondary-text-color-default);
  --spectrum-alias-button-secondary-icon-color-hover: var(--spectrum-alias-button-secondary-text-color-hover);
  --spectrum-alias-button-secondary-icon-color-down: var(--spectrum-alias-button-secondary-text-color-down);
  --spectrum-alias-button-secondary-icon-color-key-focus: var(--spectrum-alias-button-secondary-text-color-key-focus);
  --spectrum-alias-button-secondary-icon-color: var(--spectrum-alias-button-secondary-icon-color-default);
  --spectrum-alias-button-negative-background-color-default: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-button-negative-background-color-hover: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-background-color-down: var(--spectrum-global-color-red-700);
  --spectrum-alias-button-negative-background-color-key-focus: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-background-color: var(--spectrum-alias-button-negative-background-color-default);
  --spectrum-alias-button-negative-border-color-default: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-border-color-hover: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-border-color-down: var(--spectrum-global-color-red-700);
  --spectrum-alias-button-negative-border-color-key-focus: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-border-color: var(--spectrum-alias-button-negative-border-color-default);
  --spectrum-alias-button-negative-text-color-default: var(--spectrum-semantic-negative-text-color-small);
  --spectrum-alias-button-negative-text-color-hover: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-negative-text-color-down: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-negative-text-color-key-focus: var(--spectrum-global-color-gray-50);
  --spectrum-alias-button-negative-text-color: var(--spectrum-alias-button-negative-text-color-default);
  --spectrum-alias-button-negative-icon-color-default: var(--spectrum-alias-button-negative-text-color-default);
  --spectrum-alias-button-negative-icon-color-hover: var(--spectrum-alias-button-negative-text-color-hover);
  --spectrum-alias-button-negative-icon-color-down: var(--spectrum-alias-button-negative-text-color-down);
  --spectrum-alias-button-negative-icon-color-key-focus: var(--spectrum-alias-button-negative-text-color-key-focus);
  --spectrum-alias-button-negative-icon-color: var(--spectrum-alias-button-negative-icon-color-default);
  --spectrum-alias-input-border-color-disabled: var(--spectrum-alias-border-color-transparent);
  --spectrum-alias-input-border-color-quiet-disabled: var(--spectrum-alias-border-color-mid);
  --spectrum-alias-input-border-color-default: var(--spectrum-alias-border-color);
  --spectrum-alias-input-border-color-hover: var(--spectrum-alias-border-color-hover);
  --spectrum-alias-input-border-color-down: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-alias-input-border-color-mouse-focus: var(--spectrum-alias-border-color-mouse-focus);
  --spectrum-alias-input-border-color-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-input-border-color: var(--spectrum-alias-input-border-color-default);
  --spectrum-alias-input-border-color-invalid-default: var(--spectrum-semantic-negative-color-default);
  --spectrum-alias-input-border-color-invalid-hover: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-input-border-color-invalid-down: var(--spectrum-semantic-negative-color-down);
  --spectrum-alias-input-border-color-invalid-mouse-focus: var(--spectrum-semantic-negative-color-hover);
  --spectrum-alias-input-border-color-invalid-key-focus: var(--spectrum-alias-border-color-key-focus);
  --spectrum-alias-input-border-color-invalid: var(--spectrum-alias-input-border-color-invalid-default);
  --spectrum-alias-background-color-yellow-default: var(--spectrum-global-color-static-yellow-300);
  --spectrum-alias-background-color-yellow-hover: var(--spectrum-global-color-static-yellow-400);
  --spectrum-alias-background-color-yellow-key-focus: var(--spectrum-global-color-static-yellow-400);
  --spectrum-alias-background-color-yellow-down: var(--spectrum-global-color-static-yellow-500);
  --spectrum-alias-background-color-yellow: var(--spectrum-alias-background-color-yellow-default);
  --spectrum-alias-infieldbutton-background-color: var(--spectrum-global-color-gray-200);
  --spectrum-alias-infieldbutton-fill-loudnessLow-border-color-disabled: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessMedium-border-color-disabled: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessHigh-border-color-disabled: var(--spectrum-alias-component-background-color-disabled);
  --spectrum-alias-infieldbutton-fill-border-color-default: var(--spectrum-alias-input-border-color-default);
  --spectrum-alias-infieldbutton-fill-border-color-hover: var(--spectrum-alias-input-border-color-hover);
  --spectrum-alias-infieldbutton-fill-border-color-down: var(--spectrum-alias-input-border-color-down);
  --spectrum-alias-infieldbutton-fill-border-color-mouse-focus: var(--spectrum-alias-input-border-color-mouse-focus);
  --spectrum-alias-infieldbutton-fill-border-color-key-focus: var(--spectrum-alias-input-border-color-key-focus);
  --spectrum-alias-infieldbutton-fill-loudnessLow-background-color-default: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessLow-background-color-hover: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessLow-background-color-down: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessLow-background-color-key-focus: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessLow-background-color-disabled: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessMedium-background-color-default: var(--spectrum-alias-infieldbutton-fill-loudnessLow-background-color-default);
  --spectrum-alias-infieldbutton-fill-loudnessMedium-background-color-hover: var(--spectrum-alias-infieldbutton-fill-loudnessLow-background-color-hover);
  --spectrum-alias-infieldbutton-fill-loudnessMedium-background-color-down: var(--spectrum-alias-infieldbutton-fill-loudnessLow-background-color-down);
  --spectrum-alias-infieldbutton-fill-loudnessMedium-background-color-key-focus: var(--spectrum-alias-infieldbutton-fill-loudnessLow-background-color-key-focus);
  --spectrum-alias-infieldbutton-fill-loudnessMedium-background-color-disabled: transparent;
  --spectrum-alias-infieldbutton-fill-loudnessHigh-background-color-default: var(--spectrum-alias-component-background-color-default);
  --spectrum-alias-infieldbutton-fill-loudnessHigh-background-color-hover: var(--spectrum-alias-component-background-color-hover);
  --spectrum-alias-infieldbutton-fill-loudnessHigh-background-color-down: var(--spectrum-alias-component-background-color-down);
  --spectrum-alias-infieldbutton-fill-loudnessHigh-background-color-key-focus: var(--spectrum-alias-component-background-color-key-focus);
  --spectrum-alias-infieldbutton-fill-loudnessHigh-background-color-disabled: var(--spectrum-alias-component-background-color-disabled);
  --spectrum-alias-tabs-divider-background-color-default: var(--spectrum-global-color-gray-300);
  --spectrum-alias-tabs-divider-background-color-quiet: var(--spectrum-alias-background-color-transparent);
  --spectrum-alias-tabitem-text-color-default: var(--spectrum-alias-label-text-color);
  --spectrum-alias-tabitem-text-color-hover: var(--spectrum-alias-text-color-hover);
  --spectrum-alias-tabitem-text-color-down: var(--spectrum-alias-text-color-down);
  --spectrum-alias-tabitem-text-color-key-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-alias-tabitem-text-color-mouse-focus: var(--spectrum-alias-text-color-hover);
  --spectrum-alias-tabitem-text-color: var(--spectrum-alias-tabitem-text-color-default);
  --spectrum-alias-tabitem-text-color-selected-default: var(--spectrum-global-color-gray-900);
  --spectrum-alias-tabitem-text-color-selected-hover: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-text-color-selected-down: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-text-color-selected-key-focus: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-text-color-selected-mouse-focus: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-text-color-selected: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-text-color-emphasized: var(--spectrum-alias-tabitem-text-color-default);
  --spectrum-alias-tabitem-text-color-emphasized-selected-default: var(--spectrum-global-color-static-blue-500);
  --spectrum-alias-tabitem-text-color-emphasized-selected-hover: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-text-color-emphasized-selected-down: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-text-color-emphasized-selected-key-focus: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-text-color-emphasized-selected-mouse-focus: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-text-color-emphasized-selected: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-selection-indicator-color-default: var(--spectrum-alias-tabitem-text-color-selected-default);
  --spectrum-alias-tabitem-selection-indicator-color-emphasized: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-tabitem-icon-color-disabled: var(--spectrum-alias-text-color-disabled);
  --spectrum-alias-tabitem-icon-color-default: var(--spectrum-alias-icon-color);
  --spectrum-alias-tabitem-icon-color-hover: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-tabitem-icon-color-down: var(--spectrum-alias-icon-color-down);
  --spectrum-alias-tabitem-icon-color-key-focus: var(--spectrum-alias-icon-color-hover);
  --spectrum-alias-tabitem-icon-color-mouse-focus: var(--spectrum-alias-icon-color-down);
  --spectrum-alias-tabitem-icon-color: var(--spectrum-alias-tabitem-icon-color-default);
  --spectrum-alias-tabitem-icon-color-selected: var(--spectrum-alias-icon-color-selected-neutral);
  --spectrum-alias-tabitem-icon-color-emphasized: var(--spectrum-alias-tabitem-text-color-default);
  --spectrum-alias-tabitem-icon-color-emphasized-selected: var(--spectrum-alias-tabitem-text-color-emphasized-selected-default);
  --spectrum-alias-assetcard-selectionindicator-background-color-ordered: var(--spectrum-global-color-blue-500);
  --spectrum-alias-assetcard-overlay-background-color: #1b7ff51a;
  --spectrum-alias-assetcard-border-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-alias-assetcard-border-color-selected-hover: var(--spectrum-global-color-blue-500);
  --spectrum-alias-assetcard-border-color-selected-down: var(--spectrum-global-color-blue-600);
  --spectrum-alias-background-color-default: var(--spectrum-global-color-gray-100);
  --spectrum-alias-background-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-background-color-transparent: transparent;
  --spectrum-alias-background-color-overbackground-down: #fff3;
  --spectrum-alias-background-color-quiet-overbackground-hover: #ffffff1a;
  --spectrum-alias-background-color-quiet-overbackground-down: #fff3;
  --spectrum-alias-background-color-overbackground-disabled: #ffffff1a;
  --spectrum-alias-background-color-quickactions-overlay: #0003;
  --spectrum-alias-placeholder-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-alias-placeholder-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-selected: var(--spectrum-global-color-gray-800);
  --spectrum-alias-label-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-alias-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-mouse-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-alias-text-color-invalid: var(--spectrum-global-color-red-500);
  --spectrum-alias-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-overbackground: var(--spectrum-global-color-static-white);
  --spectrum-alias-text-color-overbackground-disabled: #fff3;
  --spectrum-alias-text-color-quiet-overbackground-disabled: #fff3;
  --spectrum-alias-heading-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alias-link-primary-text-color-default: var(--spectrum-global-color-blue-600);
  --spectrum-alias-link-primary-text-color-hover: var(--spectrum-global-color-blue-600);
  --spectrum-alias-link-primary-text-color-down: var(--spectrum-global-color-blue-700);
  --spectrum-alias-link-primary-text-color-key-focus: var(--spectrum-alias-text-color-key-focus);
  --spectrum-alias-link-primary-text-color: var(--spectrum-alias-link-primary-text-color-default);
  --spectrum-alias-link-secondary-text-color-default: var(--spectrum-alias-link-primary-text-color-default);
  --spectrum-alias-link-secondary-text-color-hover: var(--spectrum-alias-link-primary-text-color-hover);
  --spectrum-alias-link-secondary-text-color-down: var(--spectrum-alias-link-primary-text-color-down);
  --spectrum-alias-link-secondary-text-color-key-focus: var(--spectrum-alias-link-primary-text-color-key-focus);
  --spectrum-alias-link-secondary-text-color: var(--spectrum-alias-link-secondary-text-color-default);
  --spectrum-alias-border-color: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-hover: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-down: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-key-focus: var(--spectrum-global-color-blue-400);
  --spectrum-alias-border-color-mouse-focus: var(--spectrum-global-color-blue-500);
  --spectrum-alias-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-extralight: var(--spectrum-global-color-gray-100);
  --spectrum-alias-border-color-light: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-mid: var(--spectrum-global-color-gray-300);
  --spectrum-alias-border-color-dark: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-darker-default: var(--spectrum-global-color-gray-600);
  --spectrum-alias-border-color-darker-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-border-color-darker-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-border-color-transparent: transparent;
  --spectrum-alias-border-color-translucent-dark: #0000000d;
  --spectrum-alias-border-color-translucent-darker: #0000001a;
  --spectrum-alias-focus-color: var(--spectrum-global-color-blue-400);
  --spectrum-alias-focus-ring-color: var(--spectrum-alias-focus-color);
  --spectrum-alias-track-color-default: var(--spectrum-global-color-gray-300);
  --spectrum-alias-track-fill-color-overbackground: var(--spectrum-global-color-static-white);
  --spectrum-alias-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-alias-thumbnail-darksquare-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-thumbnail-lightsquare-background-color: var(--spectrum-global-color-static-white);
  --spectrum-alias-track-color-overbackground: #fff3;
  --spectrum-alias-icon-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-icon-color-overbackground: var(--spectrum-global-color-static-white);
  --spectrum-alias-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-key-focus: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-alias-icon-color-overbackground-disabled: #fff3;
  --spectrum-alias-icon-color-quiet-overbackground-disabled: #ffffff26;
  --spectrum-alias-icon-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-selected-neutral-subdued: var(--spectrum-global-color-gray-800);
  --spectrum-alias-icon-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-alias-icon-color-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-alias-icon-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-alias-icon-color-selected-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-image-opacity-disabled: var(--spectrum-global-color-opacity-30);
  --spectrum-alias-toolbar-background-color: var(--spectrum-global-color-gray-100);
  --spectrum-alias-code-highlight-color-default: var(--spectrum-global-color-gray-800);
  --spectrum-alias-code-highlight-background-color: var(--spectrum-global-color-gray-75);
  --spectrum-alias-code-highlight-color-keyword: var(--spectrum-global-color-fuchsia-600);
  --spectrum-alias-code-highlight-color-section: var(--spectrum-global-color-red-600);
  --spectrum-alias-code-highlight-color-literal: var(--spectrum-global-color-blue-600);
  --spectrum-alias-code-highlight-color-attribute: var(--spectrum-global-color-seafoam-600);
  --spectrum-alias-code-highlight-color-class: var(--spectrum-global-color-magenta-600);
  --spectrum-alias-code-highlight-color-variable: var(--spectrum-global-color-purple-600);
  --spectrum-alias-code-highlight-color-title: var(--spectrum-global-color-indigo-600);
  --spectrum-alias-code-highlight-color-string: var(--spectrum-global-color-fuchsia-600);
  --spectrum-alias-code-highlight-color-function: var(--spectrum-global-color-blue-600);
  --spectrum-alias-code-highlight-color-comment: var(--spectrum-global-color-gray-700);
  --spectrum-alias-categorical-color-1: var(--spectrum-global-color-static-seafoam-200);
  --spectrum-alias-categorical-color-2: var(--spectrum-global-color-static-indigo-700);
  --spectrum-alias-categorical-color-3: var(--spectrum-global-color-static-orange-500);
  --spectrum-alias-categorical-color-4: var(--spectrum-global-color-static-magenta-500);
  --spectrum-alias-categorical-color-5: var(--spectrum-global-color-static-indigo-200);
  --spectrum-alias-categorical-color-6: var(--spectrum-global-color-static-celery-200);
  --spectrum-alias-categorical-color-7: var(--spectrum-global-color-static-blue-500);
  --spectrum-alias-categorical-color-8: var(--spectrum-global-color-static-purple-800);
  --spectrum-alias-categorical-color-9: var(--spectrum-global-color-static-yellow-500);
  --spectrum-alias-categorical-color-10: var(--spectrum-global-color-static-orange-700);
  --spectrum-alias-categorical-color-11: var(--spectrum-global-color-static-green-600);
  --spectrum-alias-categorical-color-12: var(--spectrum-global-color-static-chartreuse-300);
  --spectrum-alias-categorical-color-13: var(--spectrum-global-color-static-blue-200);
  --spectrum-alias-categorical-color-14: var(--spectrum-global-color-static-fuchsia-500);
  --spectrum-alias-categorical-color-15: var(--spectrum-global-color-static-magenta-200);
  --spectrum-alias-categorical-color-16: var(--spectrum-global-color-static-yellow-200);
  --spectrum-colorloupe-express-visibility: none;
  --spectrum-colorloupe-spectrum-visibility: block;
  --spectrum-colorloupe-outer-border-color: transparent;
  --spectrum-colorloupe-outer-border-size: 0;
  --spectrum-colorloupe-outer-stroke-color: var(--spectrum-global-color-static-transparent-black-200);
  --spectrum-colorloupe-outer-stroke-width: var(--spectrum-alias-border-size-thick);
  --spectrum-colorhandle-background-offset: calc(var(--spectrum-global-dimension-static-size-25) * -1);
  --spectrum-colorhandle-inner-shadow-color: var(--spectrum-colorhandle-outer-shadow-color);
  --spectrum-colorhandle-outer-shadow-color: #0000006b;
  --spectrum-colorhandle-outer-shadow-blur: 0;
  --spectrum-colorhandle-outer-shadow-spread: var(--spectrum-alias-border-size-thin);
  --spectrum-colorcontrol-checkerboard-light-color: var(--spectrum-global-color-static-white);
  --spectrum-colorcontrol-checkerboard-dark-color: var(--spectrum-global-color-static-gray-300);
  --spectrum-slider-m-track-inside-border-radius: var(--spectrum-slider-m-track-border-radius);
  --spectrum-slider-label-text-size: var(--spectrum-global-dimension-font-size-75);
}

/*# sourceMappingURL=index.5c5463b7.css.map */
